* {
    box-sizing: border-box;
}

body {
    color: #fff;
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    overflow: hidden;
}

.hero {
    &__image {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(107, 0, 68, 0.6);
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__headline {
        font-size: 120px;
        line-height: 124px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        opacity: 0;
    }

    &__subheadline {
        font-size: 50px;
        font-weight: 300;
        text-align: center;
        margin: 0 0 2rem;
        opacity: 0;
    }
}

.copyright {
    font-size: 30px;
    text-align: center;
    margin: 0 0 4rem;
    opacity: 0;

    &__date {
        padding-right: 8px;
    }

    &__link {
        color: rgba(255, 255, 255, 0.5);
        text-decoration: none;
        transition: color .2s ease-in-out;
        border-left: 1px solid rgba(255, 255, 255, 1);
        padding-left: 16px;

        &:hover {
            color: rgba(255, 255, 255, 1);
            text-decoration: none;
            transition: color .2s ease-in-out;
        }
    }
}

.social {
    margin: 0 auto 2rem;
    padding: 0;
    list-style: none;
    display: table;
    text-align: center;

    &__item {
        display: inline-block;
        float: left;
        margin: 0 8px;
    }

    &__link {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        width: 80px;
        height: 80px;
        text-align: center;
        display: block;
        transition: background-color .2s ease-in-out;
        position: relative;
        bottom: -500px;

        &:hover {
            background: rgba(255, 255, 255, 1);
            transition: background-color .2s ease-in-out;
        }
    }

    &__icon {
        color: rgba(107, 0, 68, 1);
        font-size: 40px;
        line-height: 80px;

        &.fa-github {
            font-size: 50px;
        }
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@import "components/component_name";
